'use strict'

angular
  .module 'mundoMap'
  .config ($stateProvider) ->
    $stateProvider
      .state 'mundoMap',
        url: '/mundo-map'
        templateUrl: 'mundo-map/views/mundo-map.tpl.html'
        controller: 'MundoMapCtrl'
        controllerAs: 'mundoMap'
